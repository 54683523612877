import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './Main';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route index path="/" element={<Main lang="fr" />}/>
          <Route path="/en" element={<Main lang="en" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
